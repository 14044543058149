import { gql } from '@apollo/client';

export const REACTIVATE_PERSON_MUTATION = gql`
  mutation ReactivatePerson(
    $externalKey: ID
    $relationships: [PersonRelationshipInput]
    ) {
    reactivatePerson(
      externalKey: $externalKey
      relationships: $relationships
      ) {
      activation
    }
  }
`;
