import { FontIcon } from '@parkhub/parkhub-ui';
import cx from 'classnames';

interface IProps {
  action: () => void;
  label: string;
  active: boolean;
  disabled: boolean;
}

const FilterGroupListItem = ({
  action = () => {},
  label = '',
  active = false,
  disabled = false
}: IProps) => (
  <li className={cx('filter-group-list-item', { 'filter-group-list-item-disabled': disabled && !active })} onClick={action}>
    <div className="filter-group-list-item-content">
      <div className="filter-group-list-item-icon-left">
        {active && <FontIcon className="check-icon">check</FontIcon>}
      </div>
      <div className="filter-group-list-item-label">
        {label}
      </div>
      <div className="filter-group-list-item-icon-right">
        <FontIcon className="navigate-icon">navigate_next</FontIcon>
      </div>
    </div>
  </li>
);

export default FilterGroupListItem;
