import { gql } from '@apollo/client';

export const UPDATE_PERMISSION_GROUP_MUTATION = gql`
  mutation UpdatePermissionGroupMutation(
    $externalKey: ID
    $displayName: String
    $permissions: [PermissionInput]
  ) {
    updatePermissionGroup(
      externalKey: $externalKey
      displayName: $displayName
      permissions: $permissions
    ) {
      displayName
      externalKey
      organizationKey
      permissions {
        scope
        path
      }
      permissionsSummary {
        read
        write
      }
      searchName
    }
  }
`;
