import { gql } from '@apollo/client';

export const PERMISSION_GROUP_QUERY = gql`
  query PermissionGroupQuery(
    $externalKey: ID
    $searchName: String
    $organizationKey: ID
  ) {
    permissionGroups(
      externalKey: $externalKey
      searchName: $searchName
      organizationKey: $organizationKey
    ) {
      externalKey
      displayName
      organizationKey
      persons {
        status
      }
      searchName
      permissions {
        path
        scope
      }
      permissionsSummary {
        read
        write
      }
    }
  }
`;
