import { useState, useRef } from 'react';
import { PersonsQuery_persons_persons } from '../../../types/PersonsQuery';
import { LinearProgress, Button, DropdownMenu, MenuItem } from 'react-md';
import { PersonsQueryVariables } from '../../../types/PersonsQuery';
import { SearchInput, SendRegistrationModal, DeactivatePersonsModal, ListLoading, useGlobalContext } from '..';
import UsersList from './UsersList';
import ActionBar from '../ActionBar/UsersActionBar';
import CreateUserModal from '../UserModals/CreateUserModal';
import EditUserModal from '../UserModals/EditUserModal';
import AddPersonsToGroupModal from '../UpdatePermissionGroupModal/AddPersonsToGroupModal';
import ReactivateModal from '../ReactivateModal/ReactivateModal';
import cx from 'classnames';
import './Users.scss';

export interface IBodyElementChild {
  displayValue: string | number;
  sortValue: string | number;
}

export interface IBodyElement {
  name: IBodyElementChild;
  email: IBodyElementChild;
  permission_group: IBodyElementChild;
  status: IBodyElementChild;
  externalKey: string;
}

export const Users = () => {
  const {
    filters,
    organizationsData,
    organizationsError,
    organizationsLoading,
    permissionGroupData,
    permissionGroupLoading,
    permissionGroupError,
    personsData,
    personsFetchMore,
    personsError,
    personsLoading,
    personsRefetch,
    personFiltersData
  } = useGlobalContext();
  const ref = useRef<HTMLInputElement>(null);
  const [createUserModal, setCreateUserModal] = useState<boolean>(false);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [registrationModal, setRegistrationModal] = useState<boolean>(false);
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [addPersonsToGroupModal, setAddPersonsToGroupModal] = useState<boolean>(false);
  const [reactivateModal, setReactivateModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [editData, setEditData] = useState<any>();
  const [offset, setOffset] = useState<number>(50);

  const body: IBodyElement[] = personsData?.persons.persons.map((person: PersonsQuery_persons_persons) => ({
    name: {
      displayValue: person.displayName || '-',
      sortValue: person?.displayName?.toLowerCase() || ''
    },
    email: {
      displayValue: person.email || '-',
      sortValue: person.email || ''
    },
    permission_group: {
      displayValue: person?.permissionGroups![0]?.displayName || '-',
      sortValue: person?.permissionGroups![0]?.displayName || '-'
    },
    status: {
      displayValue: person?.status?.replace('_', ' ').toLowerCase() || '-',
      sortValue: person.status || ''
    },
    externalKey: person.externalKey
  })).sort((a: IBodyElement, b: IBodyElement) => a.name.sortValue > b.name.sortValue ? 1 : -1);

  const head = [{
    id: 'name',
    label: 'Name',
    sortable: true
  }, {
    id: 'email',
    label: 'Email',
    sortable: true
  }, {
    id: 'permission_group',
    label: 'Permission Group',
    sortable: true
  }, {
    id: 'status',
    label: 'Status',
    sortable: true
  }];

  const deselectAll = () => setSelected([]);

  const updateVariables = (variables: PersonsQueryVariables) => {
    if (variables.search === '') {
      variables.search = ' ';

      return personsRefetch(variables);
    }

    personsRefetch(variables);
  };

  const clearSearch = () => {
    // avoid any possible refetching
    if (ref.current?.value === '') {
      return;
    }

    if (ref.current) {
      ref.current.value = '';
      updateVariables({ search: '' });
    }
  };

  const handleCreateClick = () => {
    clearSearch();
    deselectAll();
    setCreateUserModal(!createUserModal);
  };

  const handleUpdateClick = (externalKey: string) => {
    const editUser = personsData?.persons.persons.find((user: IBodyElement) => user.externalKey === externalKey);
    deselectAll();
    clearSearch();

    const {
      firstName = '',
      lastName = '',
      email = '',
      organizations = [],
      permissionGroups = [],
      status
    } = editUser;

    setEditData({
      firstName,
      lastName,
      email,
      externalKey,
      status,
      org: {
        current: organizations.map((org: { displayName: string; externalKey: string; }) => {
          return { label: org.displayName, value: org.externalKey };
        }),
        permissions: organizations[0]?.permissionGroups
      },
      group: {
        displayName: permissionGroups[0]?.displayName,
        externalKey: permissionGroups[0]?.externalKey,
        permissions: permissionGroups[0]?.permissions
      }
    });

    if (status === 'DEACTIVATED') {
      setReactivateModal(!reactivateModal);
    } else {
      setEditUserModal(!editUserModal);
    }
  };

  const handleLoadMore = () => {
    personsFetchMore({ variables: { ...filters, offset } });
    setOffset(offset + 50);
  };

  const handleRegistrationClick = () => {
    clearSearch();
    setRegistrationModal(!registrationModal);
  };

  const openDeactivateModal = () => {
    clearSearch();
    setDeactivateModal(true);
  };

  const openAssignToGroupModal = () => {
    clearSearch();
    setAddPersonsToGroupModal(true);
  };

  const loading = (personsLoading || organizationsLoading || permissionGroupLoading) && !editUserModal;
  const disableActions = (organizationsLoading || organizationsError || personsLoading || personsError) && loading;
  const canLoadMore = () => personsData?.persons?.persons?.length === offset;
  const loadingMore = personsLoading && personsData?.persons?.persons?.length > 0;

  return (
    <>
      <div className="progress-container">
        {loading && <LinearProgress id="persons-linear-progress" />}
      </div>
      <section className="wrapper persons-container">
        <ActionBar
          disabled={disableActions}
          filters={personFiltersData}
          handleClick={handleCreateClick}
          update={updateVariables}
        >
          <SearchInput
            ref={ref}
            placeholder="Search by Keyword"
            onChange={(value: string) => updateVariables({ search: value })}
          />
        </ActionBar>
        {personsError && <div>Error...</div>}
        {((!personsLoading || editUserModal) || loadingMore) && (
          <UsersList
            body={body}
            handleUpdate={handleUpdateClick}
            head={head}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {(loading || loadingMore) && <ListLoading />}
        {canLoadMore() && (
          <div className="load-more-container">
            <Button
              aria-label="load-more-users"
              theme="primary"
              themeType="contained"
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          </div>
        )}
      </section>
      <footer className={cx('users-footer', selected.length > 0 && 'display-footer')}>
        <div className="footer-content">
          <Button
            aria-label="clear-selection"
            className="clear-selection"
            themeType="contained"
            onClick={() => setSelected([])}
          >
            clear selection
          </Button>
          <DropdownMenu
            buttonChildren={`Edit ${selected.length ? selected.length : ''} user${selected.length > 1 ? 's' : ''}`}
            id="user-edit-actions"
          >
            <MenuItem
              leftAddon={<i className="material-icons">email</i>}
              onClick={handleRegistrationClick}
            >
              Send registration email{selected.length > 1 && 's'}
            </MenuItem>
            <MenuItem
              disabled={permissionGroupLoading || permissionGroupError}
              leftAddon={<i className="material-icons">edit</i>}
              onClick={openAssignToGroupModal}
            >
              Assign to group
            </MenuItem>
            <MenuItem
              leftAddon={<i className="material-icons">delete</i>}
              onClick={openDeactivateModal}
            >
              Deactivate user{selected.length > 1 && 's'}
            </MenuItem>
          </DropdownMenu>
        </div>
      </footer>
      <CreateUserModal
        organizations={organizationsData?.organizations}
        permissionGroups={permissionGroupData?.permissionGroups}
        visible={createUserModal}
        onRequestClose={handleCreateClick}
      />
      <EditUserModal
        editData={editData}
        organizations={organizationsData?.organizations}
        permissionGroups={permissionGroupData?.permissionGroups}
        visible={editUserModal}
        onRequestClose={() => setEditUserModal(false)}
      />
      <SendRegistrationModal
        deselectAll={deselectAll}
        externalKeys={selected}
        visible={registrationModal}
        onRequestClose={handleRegistrationClick}
      />
      <DeactivatePersonsModal
        deselectAll={deselectAll}
        externalKeys={selected}
        visible={deactivateModal}
        onRequestClose={() => setDeactivateModal(false)}
      />
      <AddPersonsToGroupModal
        deselectAll={deselectAll}
        externalKeys={selected}
        permissionGroups={permissionGroupData?.permissionGroups}
        visible={addPersonsToGroupModal}
        onRequestClose={() => setAddPersonsToGroupModal(false)}
      />
      <ReactivateModal
        externalKey={editData?.externalKey}
        userName={`${editData?.firstName} ${editData?.lastName}`}
        visible={reactivateModal}
        onRequestClose={() => setReactivateModal(false)}
      />
    </>
  );
};

export default Users;
