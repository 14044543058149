import { FontIcon } from 'react-md';
import { isNotEmpty } from 'src/utils';
import { get } from 'lodash';

interface IPermission {
  path: string;
  scope: string;
};

function PermissionRow({ permissions, permissionPath }: { permissions: IPermission[], permissionPath: string }) {
  let permission = permissions.find(p => p.path === permissionPath);

  if (permissionPath === 'suite.users' && permissions.some(p => p.path.includes('users'))) {
    permission = {
      path: 'suite.users',
      scope: permissions.some(p => p.path.includes('users') && p.scope === 'WRITE') ? 'WRITE' : 'READ'
    };
  }

  function getLabel(path: string) {
    const pathArray = path.split('.');

    if (pathArray.includes('users')) {
      if (pathArray.length > 3) {
        return `${pathArray[pathArray.length - 3]} ${pathArray[pathArray.length - 2]} - ${pathArray[pathArray.length - 1]}`.replaceAll('_', ' ');
      }

      if (pathArray.length > 2) {
        return `${pathArray[pathArray.length - 2]} ${pathArray[pathArray.length - 1]}`.replaceAll('_', ' ');
      }
    }

    if (pathArray.length > 1) {
      if (pathArray.slice(-1)[0].toLowerCase() === 'egds') {
        return 'EGDS';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'vips') {
        return 'VIPs';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'api') {
        return 'API';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'access_control') {
        return 'User Access';
      }
      
      return get(pathArray, pathArray.length - 1).replaceAll('_', ' ');
    }

    return pathArray;
  }

  return (permission ? (
    <div className="permission-row">
      <div className="permission-label">{getLabel(permission.path)}</div>
      {/* <div className="permission-scope">
        {['READ', 'WRITE'].includes(permission.scope) && <FontIcon>check</FontIcon>}View
      </div> */}
      <div className="permission-scope">
        {permission.scope === 'WRITE' && <FontIcon>check</FontIcon>}
      </div>
    </div>
  ) :
    null
  );
}

export const PermissionsInfographic = ({ permissions }: { permissions: IPermission[]}) => (
  <div className="permissions-graphic-container">
    {isNotEmpty(permissions) && <div className='permissions-column-header'>Access</div>}
    <PermissionRow permissionPath='suite' permissions={permissions} />
    <PermissionRow permissionPath='suite.ghosting' permissions={permissions} />
    <PermissionRow permissionPath='suite.access_control' permissions={permissions} />
    <PermissionRow permissionPath='suite.api' permissions={permissions} />
    <PermissionRow permissionPath='suite.analytics' permissions={permissions} />
    <PermissionRow permissionPath='suite.devices' permissions={permissions} />
    <PermissionRow permissionPath='suite.education' permissions={permissions} />
    <PermissionRow permissionPath='suite.egds' permissions={permissions} />
    <PermissionRow permissionPath='suite.live' permissions={permissions} />
    <PermissionRow permissionPath='suite.network_diagram' permissions={permissions} />
    <PermissionRow permissionPath='suite.portal' permissions={permissions} />
    <PermissionRow permissionPath='suite.prepaid_pass_manager' permissions={permissions} />
    <PermissionRow permissionPath='suite.provisioning' permissions={permissions} />
    <PermissionRow permissionPath='suite.scheduler' permissions={permissions} />
    <PermissionRow permissionPath='suite.signal' permissions={permissions} />
    <PermissionRow permissionPath='suite.vips' permissions={permissions} />
    <PermissionRow permissionPath='suite.smartpass' permissions={permissions} />
    <PermissionRow permissionPath='suite.users' permissions={permissions} />
    <div className='users-top-level'>
      <PermissionRow permissionPath='suite.users.organizations' permissions={permissions} />
      <PermissionRow permissionPath='suite.users.lot_associations' permissions={permissions} />
      <PermissionRow permissionPath='suite.users.persons' permissions={permissions} />
      <div className='persons-top-level'>
        <PermissionRow permissionPath='suite.users.persons.create' permissions={permissions} />
        <PermissionRow permissionPath='suite.users.persons.update' permissions={permissions} />
        <PermissionRow permissionPath='suite.users.persons.invite' permissions={permissions} />
      </div>
    </div>
  </div>
);

export default PermissionsInfographic;
