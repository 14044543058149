//@ts-nocheck
import { useState } from 'react';
import { SearchIcon } from '../../assets/react-icons';
import { filter, map, findIndex, find, some, isEmpty } from 'lodash';
import { FUSE_OPTIONS, idFormatter, truncate, orderByKey } from '../../utils';
import Fuse from 'fuse.js';
import { Checkbox, Button } from 'react-md';
import './FilterSelection.scss';

const FilterSelection = ({
  onApply,
  variableKey,
  label,
  labelKey = 'name',
  data,
  formatter,
  enableSearch
}) => {
  const [initialData, setInitialData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');

  const _FUSE_OPTIONS = {
    ...FUSE_OPTIONS,
    keys: [
      'name'
    ]
  };

  const handleApply = (e) => {
    e.stopPropagation();

    const selected = map(filter(initialData, { selected: true }), (item) => item.id);
    const applied = selected.length ? selected : null;

    onApply({ [variableKey]: applied });
  };

  const handleOnChangeCheckbox = (v, item) => {
    const index = findIndex(initialData, obj => obj.id === item.id);
    const newList = [...initialData];
    newList[index].selected = v;

    setInitialData(newList);
  };

  const handleOnChangeSelectAll = (checked) => setInitialData(initialData.map(({ name, id }) => ({ name, id, selected: checked })));

  const isAllSelected = !some(initialData, ['selected', false]);

  const createList = () => {
    if (enableSearch) {
      const searchList = initialData;
      const fuse = new Fuse(searchList, _FUSE_OPTIONS);
      const results = fuse.search(searchTerm);
      const filteredWithCheckState = results.map(result => find(initialData, ['id', result.item.id]));

      return searchTerm === '' ? initialData : filteredWithCheckState;
    }

    return initialData;
  };

  const LIST = orderByKey(createList(), 'name', 'asc');

  return (
    <div className="filter-selection">
      {
        enableSearch &&
        <div className="filter-selection-search" >
          <input placeholder="Search" type="text" onChange={(e) => setSearchTerm(e.target.value)} />
          <span>
            <SearchIcon color="#949494" width={20} />
          </span>
        </div>
      }
      {!isEmpty(LIST) &&
        <Checkbox
          checked={isAllSelected}
          className="ph-checkbox top-control"
          id={`${idFormatter(label)}-top-control-select`}
          label="Select All"
          name="select-all"
          iconAfter
          onChange={(e) => handleOnChangeSelectAll(e.currentTarget.checked)}
        />
      }
      <div className="filter-selection-list-container">
        <ul className="filter-selection-list">
          {LIST && LIST.map((item, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`${item[labelKey]}-${index}`}
              title={item[labelKey]}
            >
              <Checkbox
                checked={item.selected}
                className="ph-checkbox"
                id={`${idFormatter(label)}-check-input-${index}`}
                label={formatter ? formatter(item[labelKey]) : truncate(item[labelKey], 42)}
                name={`${idFormatter(label)}-check-input-${index}`}
                title={item[labelKey]}
                iconAfter
                onChange={(e) => handleOnChangeCheckbox(e.currentTarget.checked, item)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="filter-selection-action-container">
        <Button
          className="filter-selection-apply-button"
          disabled={LIST.length === 0}
          id={`${idFormatter(label)}-filter-apply`}
          theme="primary"
          themeType="contained"
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterSelection;
