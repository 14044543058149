import { gql } from '@apollo/client';

export const DEACTIVATE_PERSONS_MUTATION = gql`
  mutation DeactivatePersonsMutation(
    $externalKeys: [ID]
    ) {
    deactivatePersons(
      externalKeys: $externalKeys
      ) {
      personsDeactivated
    }
  }
`;
