import { gql } from '@apollo/client';

export const ADD_PERSONS_TO_PERMISSION_GROUP_MUTATION = gql`
  mutation AddPersonsToPermissionGroupMutation(
    $permissionGroupExternalKey: ID
    $personExternalKeys: [ID]
  ) {
    addPersonsToPermissionGroup(
      permissionGroupExternalKey: $permissionGroupExternalKey
      personExternalKeys: $personExternalKeys
    ) {
      displayName
    }
  }
`;
