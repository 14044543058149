import { ReactNode, createContext, useState } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { PERSONS_QUERY, PERMISSION_GROUP_QUERY, ORGANIZATIONS_QUERY, PERSON_FILTERS } from '../../graphql';
import { PersonsQuery, PersonsQueryVariables } from '../../../types/PersonsQuery';
import { PermissionGroupQuery, PermissionGroupQueryVariables } from '../../../types/PermissionGroupQuery';
import { OrganizationsQuery, OrganizationsQueryVariables } from '../../../types/OrganizationsQuery';
import { PersonFilters } from '../../../types/PersonFilters';
import { checkRole, getAuthUser } from '../../utils';

interface IProps {
  children: ReactNode;
};

export interface IGlobalContext {
  filters: PersonsQueryVariables;
  organizationsRefetch: (variables: Partial<OrganizationsQueryVariables> | undefined) => Promise<ApolloQueryResult<OrganizationsQuery>>;
  organizationsLoading: boolean;
  organizationsError: any;
  organizationsData: OrganizationsQuery | undefined;
  personFiltersRefetch: (variables: Partial<OrganizationsQueryVariables> | undefined) => Promise<ApolloQueryResult<PersonFilters>>;
  personFiltersLoading: boolean;
  personFiltersError: any;
  personFiltersData: PersonFilters | undefined;
  personsData: PersonsQuery;
  personsError: any;
  personsFetchMore: any;
  personsLoading: boolean;
  personsRefetch: (variables: Partial<PersonsQueryVariables> | undefined) => Promise<ApolloQueryResult<PersonsQuery>>;
  setFilters: (filters: PersonsQueryVariables) => void;
  permissionGroupData: PermissionGroupQuery | undefined;
  permissionGroupError: any;
  permissionGroupLoading: boolean;
  permissionGroupRefetch: (variables: Partial<PermissionGroupQueryVariables> | undefined) => Promise<ApolloQueryResult<PermissionGroupQuery>>;
}

export const GlobalContext = createContext<IGlobalContext | any>({});
const authUser = getAuthUser();
const globalAdmin = checkRole(authUser, 'GLOBAL_ADMIN');
const phKey = globalAdmin && authUser?.g.find((org: { r: string, o: string }) => org.r === 'GLOBAL_ADMIN')?.o;

export function GlobalContextProvider({ children }: IProps) {
  const [filters, setFilters] = useState<PersonsQueryVariables>({
    offset: 0,
    limit: 50,
    status: [],
    permissionGroupExternalKey: [],
    includeDeactivated: true
  });

  const {
    refetch: personFiltersRefetch,
    loading: personFiltersLoading,
    error: personFiltersError,
    data: personFiltersData
  } = useQuery<PersonFilters>(PERSON_FILTERS);

  const orgVariables = {
    limit: 0,
    offset: 0,
    search: phKey
  };

  if (!globalAdmin) {
    delete orgVariables.search;
  }

  const {
    refetch: organizationsRefetch,
    loading: organizationsLoading,
    error: organizationsError,
    previousData: previousOrganizationsData,
    data: organizationsData = previousOrganizationsData
  } = useQuery<OrganizationsQuery, OrganizationsQueryVariables>(ORGANIZATIONS_QUERY, {
    variables: orgVariables,
    notifyOnNetworkStatusChange: true
  });

  const {
    fetchMore: personsFetchMore,
    refetch: personsRefetch,
    loading: personsLoading,
    error: personsError,
    previousData: previousPersonsData,
    data: personsData = previousPersonsData
  } = useQuery<PersonsQuery, PersonsQueryVariables>(PERSONS_QUERY, {
    variables: filters,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const {
    refetch: permissionGroupRefetch,
    loading: permissionGroupLoading,
    error: permissionGroupError,
    previousData: previousPermissionGroupData,
    data: permissionGroupData = previousPermissionGroupData
  } = useQuery<PermissionGroupQuery, PermissionGroupQueryVariables>(PERMISSION_GROUP_QUERY, { notifyOnNetworkStatusChange: true });

  return (
    <GlobalContext.Provider
      value={{
        filters,
        organizationsRefetch,
        organizationsLoading,
        organizationsError,
        organizationsData,
        personFiltersRefetch,
        personFiltersLoading,
        personFiltersError,
        personFiltersData,
        personsData,
        personsError,
        personsFetchMore,
        personsLoading,
        personsRefetch,
        setFilters,
        permissionGroupData,
        permissionGroupError,
        permissionGroupLoading,
        permissionGroupRefetch
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
