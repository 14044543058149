import './index.scss';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import reportWebVitals from './reportWebVitals';
import { Configuration, MessageQueue } from 'react-md';
import { BrowserRouter } from 'react-router-dom';

const client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.REACT_APP_CORE_USER_API_URL}/graphql`,
    credentials: 'include'
  }),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          persons: {
            keyArgs: false,
            merge(existing = { persons: [] }, incoming) {
              return {
                ...existing,
                ...incoming,
                persons: [...existing.persons, ...incoming.persons]
              };
            }
          }
        }
      }
    }
  })
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Configuration>
        <MessageQueue id="alert-dialog">
          <App />
        </MessageQueue>
      </Configuration>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
