import { useEffect, useState, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useGlobalContext } from '..';
import {
  AutoComplete,
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  FontIcon,
  TextField,
  useAddMessage
} from 'react-md';
import PermissionsOptions from './PermissionOptions';
import { CREATE_PERMISSION_GROUP_MUTATION, UPDATE_PERMISSION_GROUP_MUTATION } from '../../graphql';
import { isNotEmpty } from 'src/utils';
import './PermissionGroupModal.scss';

interface IPermission {
  scope: string;
  path: string;
}

interface IEditPermissions {
  displayName: string;
  permissions: [IPermission];
  externalKey: string;
  organizationKey: string;
}

interface Organizations {
  externalKey: string;
  displayName: string;
}

function CreatePermissionGroupModal({
  visible,
  onRequestClose,
  availablePermissions,
  editPermission,
  orgs = []
}: { visible: boolean, onRequestClose: () => void, availablePermissions: [IPermission], editPermission?: IEditPermissions, orgs: [Organizations] | [] }) {
  const addMessage = useAddMessage();
  const [groupName, setGroupName] = useState<string>('');
  const { permissionGroupRefetch } = useGlobalContext();
  const [selectedPermissions, setSelectedPermissions] = useState<IPermission[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<{ name: string, id: string | null }>();
  const [orgKey, setOrgKey] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const autoCompleteData = useMemo(() => orgs?.map((org) => ({ name: org.displayName, id: org.externalKey })), [orgs]);

  useEffect(() => {
    if (editPermission?.permissions) {
      setSelectedPermissions(editPermission.permissions);
    }
  }, [editPermission]);

  useEffect(() => {
    if (editPermission?.displayName) {
      setGroupName(editPermission.displayName);
    }
  }, [editPermission]);

  useEffect(() => {
    if (editPermission?.organizationKey && autoCompleteData) {
      const existingOrg = autoCompleteData.find((org) => editPermission?.organizationKey === org.id);
      
      setOrgKey(existingOrg?.id || '');
      setSelectedOrg(existingOrg);
    }
  }, [editPermission, autoCompleteData]);



  const [createPermissionGroup] = useMutation(CREATE_PERMISSION_GROUP_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; Permission group created successfully.</>,
        action: 'dismiss'
      });
      permissionGroupRefetch();
      handleClose();
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error creating permission group.</>,
        action: 'dismiss'
      });
      handleClose();
    }
  });

  const [updatePermissionGroup] = useMutation(UPDATE_PERMISSION_GROUP_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; Permission group successfully updated.</>,
        action: 'dismiss'
      });
      permissionGroupRefetch();
      handleClose();
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error updating permission group.</>,
        action: 'dismiss'
      });
      handleClose();
    }
  });

  function handleName({ currentTarget }: any) {
    setGroupName(currentTarget.value);
  }

  function handleSave() {
    const permissions = selectedPermissions.filter((permission) => !['suite', 'suite.users', 'suite.users.persons'].includes(permission.path));
    setSaving(true);

    if (editPermission) {
      updatePermissionGroup({
        variables: {
          externalKey: editPermission.externalKey,
          displayName: groupName,
          permissions
        }
      });
    } else {
      createPermissionGroup({
        variables: {
          displayName: groupName,
          organizationKey: orgKey,
          permissions
        }
      });
    }
  }

  function handleClose() {
    setGroupName('');
    setSelectedOrg(undefined);
    setOrgKey('');
    setSelectedPermissions([]);
    onRequestClose();
  }

  const handleAutoComplete = (data: any) => {
    setOrgKey(data?.result?.id);
  };

  const groupNameError = !!groupName && (groupName.length < 1 || groupName?.length > 64);
  const disableTextField = editPermission && ['admin', 'default'].includes(editPermission.displayName.toLowerCase());
  const adminGroupSelected = editPermission?.displayName.toLowerCase() === 'admin';
  const isSaveDisabled = saving || !groupName || adminGroupSelected || !orgKey || !isNotEmpty(selectedPermissions);

  return (
    <Dialog
      aria-label="create-permission-group-dialog"
      id="create-permission-group-dialog"
      visible={visible}
      onExited={() => setSaving(false)}
      onRequestClose={handleClose}
    >
      <DialogHeader>
        <div className="fill" />
        <h1>{editPermission ? 'Edit' : 'New'} Group</h1>
        <Button
          aria-label="close-create-permission-group-dialog"
          buttonType="icon"
          className="close-dialog-button"
          theme="primary"
          onClick={handleClose}
        >
          <FontIcon>close</FontIcon>
        </Button>
      </DialogHeader>
      <DialogContent className="dialog-content">
        <div className="group-name-container">
          <h3>Group name</h3>
          <TextField
            disabled={disableTextField}
            error={groupNameError}
            id="group-name"
            maxLength={64}
            minLength={1}
            placeholder='Group name'
            value={groupName}
            onChange={handleName}
          />
        </div>
        <div className="group-name-container">
          <h3>Owning Organization</h3>
          <AutoComplete
            className="orgs-text-input"
            data={autoCompleteData}
            disabled={editPermission ? true : false}
            filter="fuzzy"
            id="organization-name-input"
            labelKey="name"
            name="organization-name-input"
            placeholder='Owning Organization'
            value={selectedOrg?.name}
            valueKey="name"
            onAutoComplete={handleAutoComplete}
            onChange={() => setOrgKey('')}
          />
        </div>
        <div>
          <div className="permissions-container">
            <h3>Permissions by ParkHub Suite App</h3>
            <PermissionsOptions
              availablePermissions={availablePermissions}
              disabled={adminGroupSelected}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button
          aria-label="save-new-group"
          disabled={isSaveDisabled}
          id="save-new-group"
          theme="primary"
          themeType="contained"
          onClick={handleSave}
        >
          {editPermission ? 'Update' : 'Save'} Group
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default CreatePermissionGroupModal;
