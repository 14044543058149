import { gql } from '@apollo/client';

export const PERSON_FILTERS = gql`
  query PersonFilters {
    personFilters {
        permissionGroup {
        externalKey
        name
      }
      status
    }
  }
`;
