import { gql } from '@apollo/client';

export const ORGANIZATIONS_QUERY = gql`
  query OrganizationsQuery(
    $status: String
    $groupKey: ID
    $limit: Int
    $offset: Int
    $search: String
  ) {
    organizations(
      status: $status
      groupKey: $groupKey
      limit: $limit
      offset: $offset
      search: $search
    ) {
      displayName
      externalKey
      permissionGroups {
        externalKey
        displayName
        permissions {
          path
          scope
        }
      }
    }
  }
`;
