import type { IBodyElement } from './Users';
import { useState, useEffect } from 'react';
import { Checkbox } from 'react-md';
import { isNotEmpty } from '../../utils';
import { SortUpIcon, SortDownIcon, SortInactiveIcon } from '../../assets/react-icons';
import cx from 'classnames';
import Arrow from '../../assets/react-icons/Arrow';
import { orderBy, union } from 'lodash';

interface IListData {
  [key: string]: string | number | null
}

type TListData = keyof IListData;
type TSortDirection = 'asc' | 'desc';

interface IProps {
  body: IBodyElement[];
  head: { id: string, label: string, sortable: boolean }[];
  selected: Array<string>;
  setSelected: Function;
  handleUpdate: (externalKey: string) => void;
}

function UsersList({ body, head, selected, setSelected, handleUpdate }: IProps) {
  const [sortColumn, setSortColumn] = useState<TListData>('name');
  const [sortDirection, setSortDirection] = useState<TSortDirection>('asc');
  const [data, setData] = useState(body);

  const sort = (list: any, key: TListData, direction: TSortDirection) => orderBy(list, `${key}.sortValue`, [direction]);

  useEffect(() => {
    setData(sort(body, 'name', 'asc'));
    setSortColumn('name');
    setSortDirection('asc');
  }, [body]);

  const handleChange = (value: string, rowSelected: boolean) => {
    if (rowSelected) {
      return setSelected(selected.filter((selection) => selection !== value));
    }

    setSelected([...selected, value]);
  };

  const isAllSelected = () => body.every(({ status, externalKey }) => status.displayValue === 'deactivated' || selected.includes(externalKey));
  const allDeactivated = body.every(({ status }) => status.displayValue === 'deactivated');

  const handleSelectAll = () => {
    const activeUsers: IBodyElement[] = body.filter((person) => person.status.displayValue !== 'deactivated');

    if (isAllSelected()) {
      return setSelected(selected.filter((selection) => !body.map(({ externalKey }) => externalKey).includes(selection)));
    }
    
    setSelected(union(selected, activeUsers.map(({ externalKey }) => externalKey)));
  };

  const setSortOptions = (key: TListData, direction: TSortDirection) => {
    setData(sort(body, key, direction));
    setSortColumn(key);
    setSortDirection(direction);
  };

  const allSelected = isAllSelected() && !allDeactivated;

  return (
    <>
      {isNotEmpty(body) ? (
        <>
          <div className="user-list-headers">
            <div className="users-header-select-all">
              <label htmlFor="user-select-all">
                <Checkbox
                  aria-label={`${allSelected ? 'deselect' : 'select'} all users`}
                  checked={allSelected}
                  data-cy="user-select-all"
                  disabled={allDeactivated}
                  id="user-select-all"
                  onChange={() => handleSelectAll()}
                />
              </label>
            </div>
            {head.map(({ id, label, sortable }) => {
              const currentItem = sortColumn === id;
              const direction = currentItem ? sortDirection : '';
              const icon = currentItem ? (direction === 'asc' ? <SortUpIcon /> : <SortDownIcon />) : <SortInactiveIcon />;

              return (
                <div key={id} className={`users-header-${id}`}>
                  <button
                    aria-label={`Sort table by ${label.toLowerCase()} in ${direction === 'asc' ? 'descending' : 'ascending'} order`}
                    className="table__button--sort"
                    data-cy={`sort-${id}`}
                    disabled={!sortable}
                    type="button"
                    onClick={() => setSortOptions(id, direction === 'asc' ? 'desc' : 'asc')}
                  >
                    <span>{label}</span>
                    {sortable && icon}
                  </button>
                </div>
              );
            })}
          </div>
          {data.map(({ name, email, permission_group, status, externalKey }) => {
            const deactivated = status.displayValue === 'deactivated';
            const rowSelected = selected.includes(externalKey);

            return (
              <ul
                key={externalKey}
                className={cx(deactivated && 'deactivated', 'list-item-row')}
                data-cy="user-list-item"
              >
                <li className="user-checkbox">
                  <Checkbox
                    key={externalKey}
                    aria-label={`Select ${name.displayValue}`}
                    checked={rowSelected}
                    data-cy={`user-checkbox-${rowSelected}`}
                    disabled={deactivated}
                    id={externalKey}
                    onChange={() => handleChange(externalKey, rowSelected)}
                  />
                </li>
                <li className="user-display-name" data-cy="display-name">
                  {name.displayValue}
                </li>
                <li className="user-email" data-cy="email">
                  {email.displayValue}
                </li>
                <li className="user-permission-group" data-cy="permission-group">
                  {permission_group.displayValue}
                </li>
                <li className="user-status" data-cy="user-status">
                  {status.displayValue}
                </li>
                <li className="user-action">
                  <button
                    aria-label={`Edit ${name.displayValue}`}
                    title="edit-user"
                    onClick={() => handleUpdate(externalKey)}
                  >
                    <Arrow />
                  </button>
                </li>
              </ul>
            );
          })}
        </>
      ) : (
        <h2 className="data-alert">No data to show. Try refreshing or removing any active filters.</h2>
      )}
    </>
  );
}

export default UsersList;
