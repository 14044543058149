import { NavLink } from 'react-router-dom';
import { useGlobalContext } from '..';
import './TabNavigation.scss';

export const TabNavigation = () => {
  const { personsRefetch } = useGlobalContext();

  return (
    <nav className="tab-navigation">
      <div className="wrapper">
        <NavLink
          className={({ isActive }) => ((isActive ? 'nav-link active-0 active' : 'nav-link'))}
          data-cy="permission-groups-navigation-tab"
          to="/permission-groups"
          onClick={() => personsRefetch()}
        >
          Permission Groups
          <div className="bottom-border" />
        </NavLink>
        <NavLink
          className={({ isActive }) => ((isActive ? 'nav-link active-1 active' : 'nav-link'))}
          data-cy="users-navigation-tab"
          to="/users"
          onClick={() => personsRefetch()}
        >
          Users
          <div className="bottom-border" />
        </NavLink>
      </div>
    </nav>
  );
};



export default TabNavigation;
