import axios from 'axios';

import {
  getAuthUser,
  getPermissions,
  checkForAccess,
  createFeatureAccess,
  getSessionTime,
  checkForEnvMatch,
  getCookie,
  checkRole
} from '@parkhub/parkhub-ui';

export {
  getAuthUser,
  getPermissions,
  checkForAccess,
  createFeatureAccess,
  getSessionTime,
  getCookie,
  checkRole
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  withCredentials: true
});

/**
 * Sign out user and redirect to suite login
 */
export function signOut(urlParams: string = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + urlParams}`);
}

/**
 * Check if user is authenticated if cookie is valid, env matches, and user has permissions true : false
 */
export const checkAuth = (): boolean => {
  const authUser = getAuthUser();
  const hasAppAccess = checkForAccess('suite.access_control');

  return !!authUser && checkForEnvMatch() && hasAppAccess;
};

/**
 * Get updated user and perm cookies
 */
export function refreshSession(): Promise<any> {
  return new Promise((resolve, reject) => {
    const cookie = getCookie('86553b39');

    if (cookie) {
      resolve(axiosInstance.post('authenticate?refreshCookie=true'));

    } else {
      reject(`Can not refresh session because current cookie expired`);
    }
  });
}

/**
 * Redirect to suite login
 */
export function redirectToSignIn(): void {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`);
};
