/* eslint-disable react/no-array-index-key */
import { useState, ReactNode, useEffect } from 'react';
import cx from 'classnames';
import { ThItem } from './ThItem';
import { orderBy } from 'lodash';
import './Table.scss';

export interface TableHeadItem {
  id: string;
  label: string | null | ReactNode;
  sortable: boolean;
  formatter?: (value: any) => any;
  style?: Record<string, any>;
  colClassName?: string;
  thClassName?: string;
  tdClassName?: string;
}

export interface TableBodyItem {
  [key: string]: {
    sortValue: string | number;
    displayValue: number | string | ReactNode | null;
  }
}

interface TableProps {
  maxTableHeight?: number;
  className?: string;
  head: TableHeadItem[];
  body: TableBodyItem[];
  defaultSortColumn?: string;
  defaultSortDirection?: 'asc' | 'desc';
}

const sortData = (list: TableBodyItem[], column: string, direction: 'asc' | 'desc' | undefined) => column ? orderBy(list, [`${column}.sortValue`], direction) : list;

export const Table = ({ className, head, body, defaultSortDirection, defaultSortColumn, maxTableHeight }: TableProps) => {
  const [tableData, setTableData] = useState<TableBodyItem[]>([]);
  const [activeSortColumn, setActiveSortColumn] = useState(defaultSortColumn || '');
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection || 'asc');

  useEffect(() => {
    setTableData(sortData(body, activeSortColumn, activeSortDirection));
  }, [body, activeSortColumn, activeSortDirection]);


  const setSortOptions = (column: string) => {
    const direction = activeSortDirection === 'asc' ? 'desc' : 'asc';

    setTableData(sortData(body, column, direction));
    setActiveSortColumn(column);
    setActiveSortDirection(direction);
  };

  return (
    <div className="table-container" style={{ maxHeight: maxTableHeight }}>
      <table className={cx(className, 'table')}>
        <colgroup>
          {head.map((item) => (
            <col key={item.id} className={item?.colClassName} style={item?.style} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {head.map((item) => (
              <ThItem
                key={item.id}
                activeSortColumn={activeSortColumn}
                activeSortDirection={activeSortDirection}
                className={cx(item?.thClassName)}
                columnKey={item.id}
                sortable={item.sortable}
                thClassName="th"
                onClick={setSortOptions}>
                {item.label}
              </ThItem>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((bodyItem, index) => {
            return (
              <tr key={`row-${index}`} className={cx('table-row-body', bodyItem?.status?.displayValue === 'deactivated' && 'disabled')}>
                {head.map((headerItem, hIndex) => {
                  const cell = bodyItem[headerItem.id];

                  return (
                    <td
                      key={`cell-${headerItem.id}-${hIndex}`}
                      className={headerItem?.tdClassName}
                      title={typeof cell.displayValue === 'string' ? cell.displayValue : undefined}
                    >
                      {cell?.displayValue}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
