import { InputHTMLAttributes, forwardRef } from 'react';
import { SearchFontIcon, CircularProgress } from 'react-md';
import cx from 'classnames';
import { debounce } from 'lodash';
import './SearchInput.scss';

export interface SearchInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  placeholder?: string;
  className?: string;
  delay?: number;
  loading?: boolean;
  onChange: (value: string) => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({
  onChange,
  placeholder,
  className,
  delay = 600,
  loading,
  ...props
}, ref) => {
  const debouncedSearch = debounce((input) => onChange(input), delay);
  const handleSearchChange = (value: string) => {
    debouncedSearch(value);
  };

  return (
    <div className={cx('search-input-container', className)}>
      <input
        ref={ref}
        className="input"
        placeholder={placeholder}
        type="text"
        onChange={(e) => handleSearchChange(e.target.value)}
        {...props}
      />
      <span className="search-icon">
        <SearchFontIcon
          color="rgba(0,0,0,.6)"
        />
      </span>
      {loading && (
        <span className="spinner">
          <CircularProgress id="search-spinner" small />
        </span>
      )}
    </div>
  );
});
export default SearchInput;
