import { HTMLAttributes } from 'react';
import cx from 'classnames';

export interface SVGIconProps extends HTMLAttributes<SVGSVGElement> {
  className?: string;
}

export const SortInactiveIcon = ({ className, ...props }: SVGIconProps) => (
  <svg
    {...props}
    className={cx(className, 'svg-icon')}
    fill="none"
    viewBox="0 0 14 20"
  >
    <path d="m4 7 3-3 3 3m0 6-3 3-3-3" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeOpacity=".1" strokeWidth="2" />
  </svg>
);

export default SortInactiveIcon;
