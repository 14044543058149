import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FontIcon,
  TextField,
  useAddMessage
} from 'react-md';
import Select from 'react-select';
import { useMutation } from '@apollo/client';
import { UPDATE_PERSON_MUTATION } from '../../graphql';
import { SendRegistrationModal, DeactivatePersonsModal, PermissionsInfographic, useGlobalContext } from '..';
import { isNotEmpty } from '../../utils';
import _ from 'lodash';
import cx from 'classnames';
import './UserModals.scss';

interface IPermission {
  scope: string;
  path: string;
}

interface IPermissionsObject {
  displayName: string;
  externalKey: string;
  permissions: IPermission[];
}

interface IEditData {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  externalKey: string;
  status: string;
  org: { current: [{ label: string; value: string }], permissions: IPermissionsObject[] };
  group: { displayName: string; externalKey: string, permissions: IPermission[] };
}

interface IOrganization {
  displayName: string;
  externalKey: string;
}

const defaultPersonInfo = {
  firstName: '',
  lastName: '',
  email: '',
  externalKey: '',
  org: { displayName: '', externalKey: '' },
  group: { displayName: '', externalKey: '' }
};

function EditUserModal({
  editData,
  onRequestClose,
  visible,
  permissionGroups,
  organizations
}: {
  editData?: IEditData
  onRequestClose: () => void,
  organizations?: IOrganization[],
  visible: boolean,
  permissionGroups: [any]
}) {
  const addMessage = useAddMessage();
  const { personsRefetch } = useGlobalContext();
  const [personInfo, setPersonInfo] = useState<any>(defaultPersonInfo);
  const [groupSelection, setGroupSelection] = useState<any>([]);
  const [deactivateDialog, setDeactivateDialog] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [registrationModalVisible, setRegistrationModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (editData) {
      const { firstName, lastName, email, org, group } = editData;
      setPersonInfo({ firstName, lastName, email, org, group, externalKey: editData.externalKey });

      if (isNotEmpty(editData?.group?.permissions)) {
        setGroupSelection(editData?.group?.permissions);
      }
    }
  }, [editData]);

  const [updatePerson] = useMutation(UPDATE_PERSON_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; User successfully updated.</>,
        action: 'dismiss'
      });

      personsRefetch();
      handleClose();
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error updating user.</>,
        action: 'dismiss'
      });
    }
  });

  const firstNameError: boolean = personInfo?.firstName && (personInfo.firstName.length < 1 || personInfo.firstName.length > 64) ? true : false;
  const lastNameError: boolean = personInfo.lastName && (personInfo.lastName.length < 1 || personInfo.lastName.length > 64) ? true : false;
  const emailValidation: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const emailError: boolean = personInfo.email && (personInfo.email.length < 2 || personInfo.email.length > 64 || !emailValidation.test(personInfo.email)) ? true : false;
  const disableSave: boolean = !personInfo.firstName || !personInfo.lastName || !personInfo.email || !(personInfo.org.current.length > 0) || editData?.status === 'DEACTIVATED';

  function handleOnChange({ currentTarget }: any) {
    setPersonInfo({ ...personInfo, [currentTarget.id]: currentTarget.value });
  }

  function handleOrgSelect(selection: any) {
    setPersonInfo({ ...personInfo, org: { current: selection } });
  }

  function handleGroupSelect(value: any) {
    setPersonInfo({ ...personInfo, group: { displayName: value.label, externalKey: value.value } });
    const permGroup = permissionGroups.find((group: { externalKey: string; }) => group.externalKey === value.value);

    setGroupSelection(permGroup.permissions);
  }

  function handleClose() {
    onRequestClose();
  }

  function handleDeactivateClick() {
    setDeactivateDialog(false);
    onRequestClose();
  }

  const handleRegistrationClick = () => {
    setRegistrationModalVisible(false);
    onRequestClose();
  };

  function handleEditUser() {
    const { firstName, lastName, email, org, group } = personInfo;

    const relationships = org.current.map((o: { value: string; label: string; }) => {
      return { organizationKey: o.value, type: o.label.toLowerCase() === 'parkhub' ? 'VIEWER' : 'OPERATOR' };
    });

    updatePerson({
      variables: {
        firstName,
        lastName,
        email,
        externalKey: personInfo.externalKey,
        displayName: `${firstName} ${lastName}`,
        permissionGroupKeys: group.externalKey ? [group.externalKey] : [],
        relationships
      }
    });
  }

  function onExit() {
    setPendingAction(false);
    setGroupSelection([]);
    setPersonInfo(defaultPersonInfo);
  }
  
  const orgOptions = organizations?.map((o: { displayName: string; externalKey: string; }) => ({ label: o.displayName, value: o.externalKey }));

  const groupOptions = _.sortBy(permissionGroups, [(o) => { return o.displayName.toLowerCase(); }]).map((p: { displayName: string; externalKey: string; }) => {
    return { label: p.displayName, value: p.externalKey };
  });


  return (
    <div>
      <Dialog
        aria-labelledby="edit-user-modal-title"
        id="user-modal"
        visible={visible}
        onExited={onExit}
        onRequestClose={handleClose}
      >
        <DialogHeader>
          <div className="fill" />
          <h1>Edit User</h1>
          <Button
            aria-label="close-user-dialog"
            buttonType="icon"
            className="close-dialog-button"
            theme="primary"
            onClick={handleClose}
          >
            <FontIcon>close</FontIcon>
          </Button>
        </DialogHeader>
        <DialogContent className="user-dialog-content">
          <h3>Personal info</h3>
          <div className="form-name-row">
            <TextField
              aria-label="First name"
              error={firstNameError}
              id="firstName"
              maxLength={64}
              minLength={1}
              placeholder="First name"
              title="First name"
              value={personInfo?.firstName}
              onChange={handleOnChange}
            />
            <TextField
              aria-label="Last name"
              error={lastNameError}
              id="lastName"
              maxLength={64}
              minLength={1}
              placeholder="Last name"
              title="Last name"
              value={personInfo?.lastName}
              onChange={handleOnChange}
            />
          </div>
          <TextField
            aria-label="Email"
            error={emailError}
            id="email"
            placeholder="Email"
            title="Email"
            value={personInfo?.email}
            onChange={handleOnChange}
          />
          <Select
            aria-label="Organization"
            className="form-field-dropdown"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            defaultValue={editData?.org?.current}
            id="organization"
            menuPortalTarget={document.body}
            name="organization"
            options={orgOptions}
            placeholder="Organization"
            styles={{
              menuPortal: base => {
                return {
                  ...base,
                  zIndex: 9999,
                  color: 'rgba(130, 130, 130, 1)'
                };
              },
              multiValueLabel: base => {
                return {
                  ...base,
                  color: 'rgba(130, 130, 130, 1)',
                  fontSize: '14px',
                  margin: '.25rem'
                };
              }
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(80, 163, 255, .1)',
                primary: 'rgba(80, 163, 255, 1)'
              }
            })}
            isMulti
            onChange={handleOrgSelect}
          />
          <h3>Permission group</h3>
          <p className="permission-description">Permissions are granted on a per-group basis, you can change these permissions in the "Permission Groups" tab.</p>
          <Select
            aria-label="Permission group"
            className="form-field-dropdown"
            classNamePrefix="select"
            id="permissionGroup"
            menuPortalTarget={document.body}
            name="permissionGroup"
            options={groupOptions}
            placeholder="Select permission group"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: 'rgba(130, 130, 130, 1)' }) }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(80, 163, 255, .1)',
                primary: 'rgba(80, 163, 255, 1)'
              }
            })}
            value={editData ? { label: personInfo.group.displayName, value: personInfo.group.externalKey } : undefined}
            onChange={(value) => handleGroupSelect(value)}
          />
          <PermissionsInfographic permissions={groupSelection} />
        </DialogContent>
        <DialogFooter className={cx(groupSelection?.length > 8 && 'permissions-overflow', 'action-container')}>
          <div className="user-actions">
            {personInfo?.externalKey && (
              <Button
                aria-label="edit-user"
                theme="warning"
                themeType="outline"
                onClick={() => setDeactivateDialog(true)}
              >
                <FontIcon>block</FontIcon>
                deactivate user
              </Button>
            )}
            {personInfo?.externalKey && (
              <Button
                aria-label="send-user-registration"
                theme="primary"
                themeType="outline"
                onClick={() => setRegistrationModalVisible(true)}
              >
                <FontIcon>email</FontIcon>
                Send registration email
              </Button>
            )}
          </div>
          <Button
            aria-label="edit-user"
            disabled={pendingAction || disableSave}
            theme="primary"
            themeType="contained"
            onClick={handleEditUser}
          >
            save changes
          </Button>
        </DialogFooter>
      </Dialog>
      <DeactivatePersonsModal externalKeys={[editData!?.externalKey]} visible={deactivateDialog} onRequestClose={handleDeactivateClick} />
      <SendRegistrationModal
        externalKeys={[personInfo?.externalKey]}
        userName={`${personInfo.firstName} ${personInfo.lastName}`}
        visible={registrationModalVisible}
        onRequestClose={handleRegistrationClick}
      />
    </div>
  );
}

export default EditUserModal;
