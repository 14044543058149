import _ from 'lodash';
export * from './authentication';

export function isNotEmpty(element: any): boolean {
  return !_.isEmpty(element);
}

export const FUSE_OPTIONS = {
  findAllMatches: false,
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeScore: false,
  minMatchCharLength: 1
};

export const idFormatter = (string: string) => string.toLowerCase().split(' ').join('-');

export function orderByKey(list: any[], key: string, direction: any) {
  return _.orderBy(list, [o => o[key].toLowerCase()], direction);
}

export function truncate(string: string, max: number) {
  return string.length > max ? string.substring(0, max) + '...' : string;
}

export const getStatusIndicatorStyle = (status: string) => {
  const statusColor = {
    INVITED: '#09BED2',
    UNKNOWN: '#DADADA',
    REGISTERED: '#02E079',
    DEACTIVATED: '#FF4E71',
    NOT_INVITED: '#F7CA18'
  };

  return statusColor[status as keyof typeof statusColor];
};
