import { ReactNode } from 'react';
import { SortUpIcon, SortDownIcon, SortInactiveIcon } from '../../assets/react-icons';
import cx from 'classnames';
import './Table.scss';

export interface ThItemProps {
  children: ReactNode;
  activeSortColumn: string;
  activeSortDirection: 'asc' | 'desc';
  columnKey: string;
  sortable: boolean;
  onClick: Function;
  className?: string;
  thClassName?: string;
}

export const ThItem = ({
  thClassName,
  className,
  children,
  activeSortColumn,
  activeSortDirection,
  columnKey,
  sortable,
  onClick
}: ThItemProps) => {
  const ascDirection = activeSortDirection === 'asc';

  function handleClick() {
    if (sortable) {
      onClick(columnKey);
    }
  }

  const activeSort = activeSortColumn === columnKey;

  const Icon = activeSort ? (ascDirection ? SortUpIcon : SortDownIcon) : SortInactiveIcon;

  return (
    <th className={cx(thClassName, { 'active-sort': activeSort, 'disabled-sort': !sortable })}>
      <div className={cx(className, 'th-item', { clickable: sortable })} onClick={handleClick}>
        <span>{children}</span>
        {sortable && <Icon className="th-icon" />}
      </div>
    </th>
  );
};

export default ThItem;
