//@ts-nocheck
import { useEffect, useRef } from 'react';
import { FontIcon } from '@parkhub/parkhub-ui';
import { useFilterGroupContext } from './FilterGroupContext';
import FilterGroupNext from './FilterGroupNext';
import FilterMenu from './FilterMenu';
import cx from 'classnames';
import './FilterGroup.scss';

const FilterGroupLayout = ({
  label,
  className,
  style
}) => {
  const {
    activeFilterCount,
    clearAllFilters,
    setReferenceElement,
    setLayoutPopperElement,
    isLayoutPopperOpen,
    setIsLayoutPopperOpen,
    setIsNextPopperOpen,
    layoutPopperStyles,
    layoutPopperAttributes,
    setNextPopperElement,
    isNextPopperOpen,
    nextPopperStyles,
    nextPopperAttributes
  } = useFilterGroupContext();

  const element = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
    };
  });

  function handleOutsideClick(e) {
    if (element.current.contains(e.target)) {
      return;
    }

    setIsLayoutPopperOpen(false);
    setIsNextPopperOpen(false);
  }

  const toggleAllPoppers = () => {
    setIsNextPopperOpen(false);

    if (!isNextPopperOpen) {
      setIsLayoutPopperOpen(!isLayoutPopperOpen);
      setIsNextPopperOpen(false);
    }
  };

  const handleClearAll = (e) => {
    e.stopPropagation();
    clearAllFilters();
  };

  const FILTER_COUNT_DISPLAY = !!activeFilterCount ? `(${activeFilterCount})` : '';

  return (
    <div ref={element} className={cx('filter-group', className)} style={style}>
      <div
        ref={setReferenceElement}
        className={cx('filter-group-button', { 'filter-group-button-active': activeFilterCount > 0 })}
        onClick={() => toggleAllPoppers()}
      >
        <div className="filter-group-button-label-container">
          <div className="filter-text">{label}</div>
          <div className="filter-count">{`${FILTER_COUNT_DISPLAY}`}</div>
        </div>
        {!!activeFilterCount ?
          <div className="filter-group-button-action" onClick={handleClearAll}>
            <FontIcon>cancel</FontIcon>
          </div>
          :
          <div className="filter-group-button-action">
            <FontIcon>{`${isLayoutPopperOpen || isNextPopperOpen ? 'arrow_drop_up' : 'arrow_drop_down'}`}</FontIcon>
          </div>
        }
      </div>
      {isLayoutPopperOpen &&
        <div
          ref={setLayoutPopperElement}
          className="filter-group-popover"
          style={layoutPopperStyles.popper}
          {...layoutPopperAttributes.popper}
          role="dialog"
        >
          <FilterMenu headerLabel={label} />
        </div>
      }
      {isNextPopperOpen &&
        <div
          ref={setNextPopperElement}
          className="filter-group-popover"
          style={nextPopperStyles.popper}
          {...nextPopperAttributes.popper}
          role="dialog"
        >
          <FilterGroupNext />
        </div>
      }
    </div>
  );
};

export default FilterGroupLayout;
