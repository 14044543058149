import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FontIcon,
  useAddMessage
} from 'react-md';
import { useGlobalContext } from '..';
import { useMutation } from '@apollo/client';
import { DEACTIVATE_PERSONS_MUTATION } from '../../graphql';
import './DeactivatePersonsModal.scss';

export const DeactivatePersonsModal = ({ deselectAll, externalKeys, visible, onRequestClose }:
  { deselectAll?: () => void, externalKeys: string[], visible: boolean, onRequestClose: () => void }) => {
  const { personsRefetch } = useGlobalContext();
  const addMessage = useAddMessage();
  const [saving, setSaving] = useState<boolean>(false);

  const [deactivatePersons] = useMutation(DEACTIVATE_PERSONS_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; User successfully deactivated.</>,
        action: 'dismiss'
      });

      personsRefetch();

      if (deselectAll) {
        deselectAll();
      }

      onRequestClose();
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error deactivating user.</>,
        action: 'dismiss'
      });

      if (deselectAll) {
        deselectAll();
      }

      onRequestClose();
    }
  });

  const handleClick = () => {
    setSaving(true);
    deactivatePersons({ variables: { externalKeys } });
  };

  return (
    <Dialog
      aria-labelledby="deactivate-user-dialog"
      id="deactivate-user-dialog"
      visible={visible}
      onExited={() => setSaving(false)}
      onRequestClose={onRequestClose}
    >
      <DialogContent className="dialog-content">
        <p className="dialog-title">
          {externalKeys.length > 1 ? `Deactivate ${externalKeys.length} accounts?` : `Are you sure you'd like to deactivate?`}
        </p>
        <div className="dialog-actions">
          <Button
            aria-label="cancel-deactivate-user"
            className="cancel-button"
            themeType="contained"
            onClick={onRequestClose}
          >
            cancel
          </Button>
          <Button
            aria-label="deactivate-user"
            disabled={saving}
            theme="warning"
            themeType="contained"
            onClick={handleClick}
          >
            deactivate
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeactivatePersonsModal;
