import { useContext } from 'react';
import { GlobalContext, IGlobalContext } from './GlobalContext';

export const useGlobalContext = () => {
  const context = useContext<IGlobalContext | any>(GlobalContext);

  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }

  return context;
};
