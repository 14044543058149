import { HTMLAttributes } from 'react';
import cx from 'classnames';

export interface SVGIconProps extends HTMLAttributes<SVGSVGElement> {
  className?: string;
}

export const SortDownIcon = ({ className, ...props }: SVGIconProps) => (
  <svg
    {...props}
    className={cx(className, 'svg-icon')}
    fill="none"
    viewBox="0 0 14 20"
  >
    <path d="m4 7 3-3 3 3" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeOpacity=".1" strokeWidth="2" />
    <path d="m10 13-3 3m0 0-3-3m3 3v-5" stroke="#50A3FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
);

export default SortDownIcon;
