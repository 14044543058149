import { Checkbox } from 'react-md';
import { get } from 'lodash';
import cx from 'classnames';

interface IPermission {
  scope: string;
  path: string;
}

interface IProps {
  availablePermissions: IPermission[];
  selectedPermissions: IPermission[];
  setSelectedPermissions: (permissionsState: IPermission[]) => void;
  disabled: boolean;
}

interface IPermissionRowProps extends IProps {
  permissionPath: string;
}

function PermissionRow(props: IPermissionRowProps) {
  const { permissionPath, selectedPermissions, setSelectedPermissions, availablePermissions, disabled } = props;
  const permissionAvailable = availablePermissions?.find(p => p?.path === permissionPath);
  const permissionSelection = selectedPermissions?.find(p => p?.path === permissionPath);

  function getLabel(path: string) {
    const pathArray = path.split('.');

    if (pathArray.includes('users')) {
      if (pathArray.length > 3) {
        return `${pathArray[pathArray.length - 3]} ${pathArray[pathArray.length - 2]} - ${pathArray[pathArray.length - 1]}`.replaceAll('_', ' ');
      }

      if (pathArray.length > 2) {
        return `${pathArray[pathArray.length - 2]} ${pathArray[pathArray.length - 1]}`.replaceAll('_', ' ');
      }
    }

    if (pathArray.length > 1) {
      if (pathArray.slice(-1)[0].toLowerCase() === 'egds') {
        return 'EGDS';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'vips') {
        return 'VIPs';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'api') {
        return 'API';
      }

      if (pathArray.slice(-1)[0].toLowerCase() === 'access_control') {
        return 'User Access';
      }

      return get(pathArray, pathArray.length - 1).replaceAll('_', ' ');
    }

    return pathArray;
  }

  function handleChange(path: string, scope: string) {
    if (path === 'suite') {
      if (!selectedPermissions.some(p => p.scope === scope)) {
        setSelectedPermissions(availablePermissions.map(p => ({ ...p, scope })));
      } else {
        setSelectedPermissions(selectedPermissions.filter(p => p.scope !== scope));
      }

      return;
    }

    if (path === 'suite.users') {
      if (!selectedPermissions.some(p => p.scope === scope && p.path.includes('suite.users'))) {
        setSelectedPermissions([
          ...selectedPermissions.filter(p => !p.path.includes('suite.users')),
          ...availablePermissions.filter(p => p.path.includes('suite.users')).map(p => ({ ...p, scope }))
        ]);
      } else {
        setSelectedPermissions(selectedPermissions.filter(p => p.scope !== scope || !p.path.includes('suite.users')));
      }

      return;
    }

    if (path === 'suite.users.persons') {
      if (!selectedPermissions.some(p => p.scope === scope && p.path.includes('suite.users.persons'))) {
        setSelectedPermissions([
          ...selectedPermissions.filter(p => !p.path.includes('suite.users.persons')),
          ...availablePermissions.filter(p => p.path.includes('suite.users.persons')).map(p => ({ ...p, scope }))
        ]);
      } else {
        setSelectedPermissions(selectedPermissions.filter(p => p.scope !== scope || !p.path.includes('suite.users.persons')));
      }

      return;
    }

    const permission = selectedPermissions.find(p => p.path === path);

    if (permission) {
      if (permission.scope === scope) {
        setSelectedPermissions(selectedPermissions.filter(p => p.path !== path));
      } else {
        setSelectedPermissions(selectedPermissions.map(p => {
          if (p.path === path) {
            return { ...p, scope };
          }

          return p;
        }));
      }
    } else {
      setSelectedPermissions([...selectedPermissions, { path, scope }]);
    }
  }

  function isChecked(scope: string | undefined, option: string) {
    if (selectedPermissions.length === 1 && selectedPermissions.find(p => p.path === 'suite')) {
      if (selectedPermissions[0].scope === option) {
        return true;
      }
    }

    if (permissionPath === 'suite') {
      const permissions = selectedPermissions.filter(p => p.path !== 'suite');

      if (permissions.some(p => p.scope === option)) {
        return true;
      }

      return false;
    }

    if (permissionPath === 'suite.users') {
      const permissions = selectedPermissions.filter(p => p.path !== 'suite.users');

      if (permissions.some(p => p.path.includes('suite.users') && p.scope === option)) {
        return true;
      }

      return false;
    }

    if (permissionPath === 'suite.users.persons') {
      const permissions = selectedPermissions.filter(p => p.path !== 'suite.users.persons');

      if (permissions.some(p => p.path.includes('suite.users.persons') && p.scope === option)) {
        return true;
      }

      return false;
    }

    return scope === option;
  }

  // const readChecked = isChecked(permissionSelection?.scope, 'READ');
  const writeChecked = isChecked(permissionSelection?.scope, 'WRITE');

  return (permissionAvailable ? (
    <div className={cx(writeChecked && 'icon-distinct', 'permission-row')}>
      <div className="permission-label">{getLabel(permissionAvailable?.path)}</div>
      {/* <Checkbox
        checked={readChecked}
        id={`${permissionPath}-view`}
        label="View"
        name={permissionSelection?.path}
        onChange={() => handleChange((permissionPath), 'READ')}
      /> */}
      <Checkbox
        checked={writeChecked}
        disabled={disabled}
        id={`${permissionPath}-edit`}
        name={permissionSelection?.path}
        onChange={() => handleChange((permissionPath), 'WRITE')}
      />
    </div>
  ) :
    null
  );
}

function PermissionsOptions(props: IProps) {
  return (
    <div className="permissions-options-container">
      <div className='permissions-column-header'>Access</div>
      <PermissionRow permissionPath='suite' {...props} />
      <div className={cx(props.availablePermissions.find(p => p.path === 'suite') && 'suite-top-level')}>
        <PermissionRow permissionPath='suite.access_control' {...props} />
        <PermissionRow permissionPath='suite.analytics' {...props} />
        <PermissionRow permissionPath='suite.api' {...props} />
        <PermissionRow permissionPath='suite.devices' {...props} />
        <PermissionRow permissionPath='suite.egds' {...props} />
        <PermissionRow permissionPath='suite.education' {...props} />
        <PermissionRow permissionPath='suite.ghosting' {...props} />
        <PermissionRow permissionPath='suite.live' {...props} />
        <PermissionRow permissionPath='suite.network_diagram' {...props} />
        <PermissionRow permissionPath='suite.portal' {...props} />
        <PermissionRow permissionPath='suite.prepaid_pass_manager' {...props} />
        <PermissionRow permissionPath='suite.provisioning' {...props} />
        <PermissionRow permissionPath='suite.scheduler' {...props} />
        <PermissionRow permissionPath='suite.signal' {...props} />
        <PermissionRow permissionPath='suite.smartpass' {...props} />
        <PermissionRow permissionPath='suite.users' {...props} />
        <div className={cx(props.availablePermissions.find(p => p.path === 'suite.users') && 'users-top-level')}>
          <PermissionRow permissionPath='suite.users.organizations' {...props} />
          <PermissionRow permissionPath='suite.users.lot_associations' {...props} />
          <PermissionRow permissionPath='suite.users.persons' {...props} />
          <div className={cx(props.availablePermissions.find(p => p.path === 'suite.users.persons') && 'persons-top-level')}>
            <PermissionRow permissionPath='suite.users.persons.create' {...props} />
            <PermissionRow permissionPath='suite.users.persons.update' {...props} />
            <PermissionRow permissionPath='suite.users.persons.invite' {...props} />
          </div>
        </div>
        <PermissionRow permissionPath='suite.vips' {...props} />
      </div>
    </div>
  );
};

export default PermissionsOptions;
