import React from 'react';
import { Button } from 'react-md';
import { FilterGroup } from '../FilterGroup/FilterGroup';
import { getStatusIndicatorStyle } from 'src/utils';
import { PersonFilters_personFilters_permissionGroup } from '../../../types/PersonFilters';
import './ActionBar.scss';

export interface FilterInputProps {
  disabled: boolean;
  filters: any;
  handleClick: () => void;
  update: (variables: any) => void;
  children: React.ReactNode;
}

export const UsersActionBar = ({ disabled, filters, handleClick, update, children }: FilterInputProps) => {

  const statusFormatter = (value: string) => {
    return {
      REGISTERED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Registered</span></>,
      INVITED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Invited</span></>,
      NOT_INVITED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Not invited</span></>,
      DEACTIVATED: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Deactivated</span></>,
      UNKNOWN: <><span className="status-indicator" style={{ backgroundColor: getStatusIndicatorStyle(value) }} /><span>Unknown</span></>
    }[value];
  };

  return (
    <div className="top-action-bar">
      <div className="action-container">
        <div className="search-input-container">
          {children}
        </div>
        <FilterGroup
          filterConfig={[
            {
              variableKey: 'permissionGroupExternalKey',
              label: 'Permission Group',
              filterOptions: filters?.personFilters?.permissionGroup?.map(({ name, externalKey }: PersonFilters_personFilters_permissionGroup) => {
                return { label: name, value: externalKey };
              }),
              enableSearch: true
            },
            {
              variableKey: 'status',
              label: 'Status',
              filterOptions: filters?.personFilters?.status,
              formatter: statusFormatter,
              enableSearch: false
            }
          ]}
          onUpdate={update}
        />
      </div>
      <Button
        aria-label="placeholder"
        disabled={disabled}
        theme="primary"
        themeType="contained"
        onClick={handleClick}
      >
        create a new user
      </Button>
    </div>
  );
};

export default UsersActionBar;
