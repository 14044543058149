//@ts-nocheck
import { createContext, useContext, useState } from 'react';
import { usePopper } from 'react-popper';
import { isEmpty, find } from 'lodash';

const FilterGroupContext = createContext([{}, () => { }]);

export const FilterGroupContextProvider = ({
  children,
  popperOptions,
  filterConfig,
  onUpdate,
  onClear
}) => {
  const [activeFilters, setActiveFilters] = useState({});
  const [filterToDisplay, setFilterToDisplay] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [layoutPopperElement, setLayoutPopperElement] = useState(null);
  const [isLayoutPopperOpen, setIsLayoutPopperOpen] = useState(false);
  const [nextPopperElement, setNextPopperElement] = useState(null);
  const [isNextPopperOpen, setIsNextPopperOpen] = useState(false);
  const { styles: layoutPopperStyles, attributes: layoutPopperAttributes } = usePopper(referenceElement, layoutPopperElement, popperOptions);
  const { styles: nextPopperStyles, attributes: nextPopperAttributes } = usePopper(referenceElement, nextPopperElement, popperOptions);

  const navigateNext = (variableKey) => {
    const selected = find(filterConfig, { variableKey });

    setIsLayoutPopperOpen(false);
    setFilterToDisplay(selected);
    setIsNextPopperOpen(true);
  };

  const navigateBack = () => {
    setIsNextPopperOpen(false);
    setFilterToDisplay(null);
    setIsLayoutPopperOpen(true);
  };

  const updateActiveFilters = (incoming) => {
    Object.keys(incoming).forEach(key => {
      if (incoming[key] === null) {
        incoming[key] = [];
      }
    });

    const newFilters = {
      ...activeFilters,
      ...incoming
    };

    onUpdate(newFilters);
    setActiveFilters(newFilters);
  };

  const clearAllFilters = () => {
    const newFilters = { ...activeFilters };

    Object.keys(newFilters).forEach(key => {
      if (['status', 'permissionGroupExternalKey'].includes(key)) {
        newFilters[key] = [];
      } else {
        newFilters[key] = null;
      }
    });

    setActiveFilters({});
    onUpdate(newFilters);

    if (onClear) {
      onClear(newFilters);
    }
  };

  const activeFilterCount = Object.entries(activeFilters).map(([, value]) => !isEmpty(value)).filter(value => value === true).length;

  return (
    <FilterGroupContext.Provider
      value={{
        activeFilters,
        clearAllFilters,
        filterToDisplay,
        filterConfig,
        activeFilterCount,
        layoutPopperAttributes,
        layoutPopperStyles,
        isLayoutPopperOpen,
        isNextPopperOpen,
        navigateBack,
        navigateNext,
        nextPopperAttributes,
        nextPopperStyles,
        setFilterToDisplay,
        setIsLayoutPopperOpen,
        setIsNextPopperOpen,
        setLayoutPopperElement,
        setNextPopperElement,
        setReferenceElement,
        updateActiveFilters
      }}
    >
      {children}
    </FilterGroupContext.Provider>
  );
};

export const useFilterGroupContext = () => useContext(FilterGroupContext);
