import { useCallback, useEffect, useRef, useState } from 'react';
import {
  checkAuth,
  getAuthUser,
  getSessionTime,
  refreshSession,
  signOut
} from '../../utils';
import { IAuthUser } from '../../../types/types';
import Layout from '../Layout/Layout';
import { GlobalContextProvider } from '../GlobalContext/GlobalContext';

const App = () => {
  const checkTime = useRef(0);
  const [authUser, setAuthUser] = useState<IAuthUser>(getAuthUser());

  const startTimeout = useCallback(() => {
    refreshSession()!.then(() => {
      clearInterval(checkTime.current);

      const checkSessionTimeout = () => {
        if (!checkAuth()) {
          signOut();
        } else {
          const minutes = getSessionTime()!;

          if (minutes > 1440) {
            signOut('?expired=true');
          } else if (minutes > 10 && minutes < 1440) {
            refreshSession();
          }
        }
      };

      checkTime.current = window.setInterval(checkSessionTimeout, 120000);
    });
  }, []);

  const checkLoggedIn = useCallback(() => {
    if (!checkAuth()) {
      window.location.replace(
        `${process.env.REACT_APP_SUITE_URL}signin?redirectUrl=${window.location.href}`
      );
    } else {
      startTimeout();
      setAuthUser(getAuthUser());
    }
  }, [startTimeout]);

  useEffect(() => {
    //@ts-ignore
    if (!window.Cypress) {
      checkLoggedIn();
    }
  }, [checkLoggedIn]);

  return (
    <>
      {!!authUser && (
        <GlobalContextProvider>
          <Layout authUser={authUser} />
        </GlobalContextProvider>
      )}
    </>
  );
};

export default App;
