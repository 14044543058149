import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FontIcon,
  useAddMessage
} from 'react-md';
import { useGlobalContext } from '..';
import { useMutation } from '@apollo/client';
import { INVITE_PERSONS_MUTATION } from '../../graphql';
import './SendRegistrationModal.scss';

export const SendRegistrationModal = ({ deselectAll, externalKeys, visible, onRequestClose, userName }:
  { deselectAll?: () => void, externalKeys: string[], visible: boolean, onRequestClose: () => void, userName?: string }) => {
  const { personsRefetch } = useGlobalContext();
  const addMessage = useAddMessage();
  const [pending, setPending] = useState<boolean>(false);

  const [invitePersons] = useMutation(INVITE_PERSONS_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; User registration email sent successfully.</>,
        action: 'dismiss'
      });
      personsRefetch();
      onRequestClose();

      if (deselectAll) {
        deselectAll();
      }
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error sending user registration email.</>,
        action: 'dismiss'
      });
      onRequestClose();

      if (deselectAll) {
        deselectAll();
      }
    }
  });

  function handleClick() {
    setPending(true);
    invitePersons({ variables: { externalKeys } });
  }

  const multipleEmailRecipients = externalKeys.length === 1 ? '1 account' : `${externalKeys.length} accounts`;
  const emailRecipients = userName ? userName : multipleEmailRecipients;

  return (
    <Dialog
      aria-labelledby="send-registration-dialog"
      id="send-registration-dialog"
      visible={visible}
      onExited={() => setPending(false)}
      onRequestClose={onRequestClose}
    >
      <DialogContent className="dialog-content">
        <p className="dialog-title">Send registration email to {emailRecipients}?</p>
        <div className="dialog-actions">
          <Button
            aria-label="cancel-user-registration"
            className="cancel-button"
            themeType="contained"
            onClick={onRequestClose}
          >
            Cancel
          </Button>
          <Button
            aria-label="send-user-registration"
            disabled={pending}
            theme="primary"
            themeType="contained"
            onClick={handleClick}
          >
            Send Email
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SendRegistrationModal;
