import { gql } from '@apollo/client';

export const UPDATE_PERSON_MUTATION = gql`
  mutation UpdatePersonMutation(
    $externalKey: ID
    $firstName: String
    $lastName: String
    $displayName: String
    $email: String
    $relationships: [PersonRelationshipInput]
    $permissionGroupKeys: [ID]
  ) {
    updatePerson(
      externalKey: $externalKey
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
      email: $email
      relationships: $relationships
      permissionGroupKeys: $permissionGroupKeys
    ) {
      externalKey
    }
  }
`;
