import { gql } from '@apollo/client';

export const ORGS_QUERY = gql`
  query OrgsQuery(
    $externalKey: [ID]
    $limit: Int
    $offset: Int
    $permissionGroupExternalKey: [ID]
    $status: [String]
    $includeDeactivated: Boolean
    $search: String
  ) {
    persons(
      externalKey: $externalKey
      limit: $limit
      offset: $offset
      permissionGroupExternalKey: $permissionGroupExternalKey
      status: $status
      includeDeactivated: $includeDeactivated
      search: $search
    ) {
      persons {
        organizations(scope: "ALL") {
          externalKey
          displayName
        }
      }
    }
  }
`;
