import { gql } from '@apollo/client';

export const CREATE_PERSON_MUTATION = gql`
  mutation CreatePersonMutation(
    $firstName: String
    $lastName: String
    $displayName: String
    $email: String
    $relationships: [PersonRelationshipInput]
    $permissionGroupKeys: [ID]
  ) {
    createPerson(
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
      email: $email
      relationships: $relationships
      permissionGroupKeys: $permissionGroupKeys
    ) {
      firstName
      externalKey
    }
  }
`;
