import { gql } from '@apollo/client';

export const CREATE_PERMISSION_GROUP_MUTATION = gql`
  mutation PermissionGroup(
    $displayName: String
    $organizationKey: ID
    $permissions: [PermissionInput]
  ) {
    createPermissionGroup(
      displayName: $displayName
      organizationKey: $organizationKey
      permissions: $permissions
    ) {
      displayName
      externalKey
      organizationKey
      permissions {
        path
        scope
      }
      permissionsSummary {
        read
        write
      }
      persons {
        firstName
        lastName
      }
      searchName
    }
  }
`;
