import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FontIcon,
  useAddMessage
} from 'react-md';
import { useGlobalContext } from '..';
import { useMutation } from '@apollo/client';
import { REACTIVATE_PERSON_MUTATION } from '../../graphql';

export const ReactivateModal = ({ externalKey, visible, onRequestClose, userName }:
  { externalKey: string, visible: boolean, onRequestClose: () => void, userName: string }) => {
  const addMessage = useAddMessage();
  const { personsRefetch } = useGlobalContext();
  const [pending, setPending] = useState<boolean>(false);

  const [reactivatePersons] = useMutation(REACTIVATE_PERSON_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; User successfully reactivated.</>,
        action: 'dismiss'
      });

      personsRefetch();
      onRequestClose();
    },
    onError: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; Error reactivating user.</>,
        action: 'dismiss'
      });
      onRequestClose();
    }
  });

  const handleClick = () => {
    setPending(true);
    reactivatePersons({ variables: { externalKey, relationships: [] } });
  };

  return (
    <Dialog
      aria-labelledby="reactivate-dialog"
      id="reactivate-dialog"
      visible={visible}
      onExited={() => setPending(false)}
      onRequestClose={onRequestClose}
    >
      <DialogContent className="dialog-content">
        <p className="dialog-title">Reactivate {userName}?</p>
        <div className="dialog-actions">
          <Button
            aria-label="cancel-reactivation"
            className="cancel-button"
            themeType="contained"
            onClick={onRequestClose}
          >
            Cancel
          </Button>
          <Button
            aria-label="reactivate"
            className="reactivate-button"
            disabled={pending}
            theme="primary"
            themeType="contained"
            onClick={handleClick}
          >
            Reactivate
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReactivateModal;
