import { gql } from '@apollo/client';

export const AVAILABLE_PERMISSIONS_QUERY = gql`
  query AvailablePermissionsQuery(
    $expand: Boolean
  ) {
    availablePermissions(
      expand: $expand
    ) {
      path
      scope
    }
  }
`;
