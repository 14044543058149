import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  FontIcon,
  TextField,
  useAddMessage
} from 'react-md';
import Select from 'react-select';
import { useMutation } from '@apollo/client';
import { CREATE_PERSON_MUTATION } from '../../graphql';
import { SendRegistrationModal, PermissionsInfographic, useGlobalContext } from '..';
import { getAuthUser, checkRole } from '../../utils';
import _ from 'lodash';
import cx from 'classnames';
import './UserModals.scss';

const defaultPersonInfo = {
  firstName: '',
  lastName: '',
  email: '',
  externalKey: '',
  orgs: [],
  group: { displayName: '', externalKey: '' }
};

export const CreateUserModal = ({
  onRequestClose,
  organizations,
  visible,
  permissionGroups
}: {
  onRequestClose: () => void,
  organizations?: [{ displayName: string, externalKey: string } | undefined]
  visible: boolean,
  permissionGroups: [any]
}) => {
  const addMessage = useAddMessage();
  const { personsRefetch } = useGlobalContext();
  const [personInfo, setPersonInfo] = useState<any>(defaultPersonInfo);
  const [externalKey, setExternalKey] = useState<string>('');
  const [groupSelection, setGroupSelection] = useState<any>([]);
  const [registrationModalVisible, setRegistrationModalVisible] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [defaultOrg, setDefaultOrg] = useState<any>(undefined);
  const [orgSelection, setOrgSelection] = useState<any>([]);
  const [availableOrgs, setAvailableOrgs] = useState<[{ displayName: string, externalKey: string } | undefined]>();
  const authUser = getAuthUser();
  const orgKey = authUser?.g.find((org: { r: string, o: string }) => org.r === 'GLOBAL_ADMIN')?.o;
  const globalAdmin = checkRole(authUser, 'GLOBAL_ADMIN');

  useEffect(() => {
    if (organizations) {
      setAvailableOrgs(organizations);
    }
  }, [organizations]);

  useEffect(() => {
    if (globalAdmin) {
      setAvailableOrgs([{ displayName: 'ParkHub', externalKey: orgKey }]);
      setOrgSelection([{ label: 'ParkHub', value: orgKey }]);
      setDefaultOrg({ label: 'ParkHub', value: orgKey });
    }
  }, [orgKey, globalAdmin]);

  const [createPerson] = useMutation(CREATE_PERSON_MUTATION, {
    onCompleted: (res) => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; User created successfully.</>,
        action: 'dismiss'
      });

      personsRefetch();
      setExternalKey(res.createPerson.externalKey);
      setRegistrationModalVisible(true);
    },
    onError: (err) => {
      const errorMessage = err.message.includes('is already registered') ? err.message : 'Error creating user.';
      setPendingAction(false);

      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; {errorMessage}</>,
        action: 'dismiss'
      });
    }
  });

  const firstNameError: boolean = personInfo?.firstName && (personInfo.firstName.length < 1 || personInfo.firstName.length > 64) ? true : false;
  const lastNameError: boolean = personInfo.lastName && (personInfo.lastName.length < 1 || personInfo.lastName.length > 64) ? true : false;
  const emailValidation: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const emailError: boolean = personInfo.email && (personInfo.email.length < 2 || personInfo.email.length > 64 || !emailValidation.test(personInfo.email)) ? true : false;
  const disableSave: boolean = !personInfo.firstName || !personInfo.lastName || !personInfo.email || orgSelection.length < 1;;

  const handleOnChange = ({ currentTarget }: any) => {
    setPersonInfo({ ...personInfo, [currentTarget.id]: currentTarget.value });
  };

  const handleOrgSelect = (selection: any) => {
    setOrgSelection(selection);
    setPersonInfo({ ...personInfo, orgs: selection });
  };

  const handleGroupSelect = (selection: any) => {
    const selected = permissionGroups?.find((group: { externalKey: string; }) => group.externalKey === selection.value);

    setPersonInfo({ ...personInfo, group: selected });
    setGroupSelection(selected.permissions);
  };

  const handleRegistrationClick = () => {
    setRegistrationModalVisible(false);
    onRequestClose();
  };

  const handleCreateUser = () => {
    const { firstName, lastName, email, orgs } = personInfo;
    let relationships = [];

    if (authUser) {
      relationships = orgSelection.map((org: { value: string; }) => ({ organizationKey: org.value, type: 'VIEWER' }));
    } else {
      relationships = orgs.map((org: { value: string; }) => ({ organizationKey: org.value, type: 'OPERATOR' }));
    }

    setPendingAction(true);
    createPerson({
      variables: {
        firstName,
        lastName,
        email,
        permissionGroupKeys: personInfo?.group.externalKey ? [personInfo?.group.externalKey] : [],
        relationships
      }
    });
  };

  const onExit = () => {
    setPendingAction(false);
    setGroupSelection([]);
    setPersonInfo(defaultPersonInfo);
  };

  const handleClose = () => onRequestClose();

  const orgOptions = _.sortBy(availableOrgs, [(o) => { return o?.displayName.toLowerCase(); }]).map((org: { displayName: string; externalKey: string; } | undefined) => {
    return { label: org?.displayName, value: org?.externalKey };
  });

  const groupOptions = _.sortBy(permissionGroups, [(o) => { return o.displayName.toLowerCase(); }]).map((p: { displayName: string; externalKey: string; }) => {
    return { label: p.displayName, value: p.externalKey };
  });

  const themeOptions = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'rgba(80, 163, 255, .1)',
      primary: 'rgba(80, 163, 255, 1)'
    }
  });

  return (
    <div>
      <Dialog
        aria-labelledby="create-user-modal-title"
        id="user-modal"
        visible={visible}
        onExited={onExit}
        onRequestClose={handleClose}
      >
        <DialogHeader>
          <div className="fill" />
          <h1>Create New User</h1>
          <Button
            aria-label="close-user-dialog"
            buttonType="icon"
            className="close-dialog-button"
            theme="primary"
            onClick={handleClose}
          >
            <FontIcon>close</FontIcon>
          </Button>
        </DialogHeader>
        <DialogContent className="user-dialog-content">
          <h3>Personal info</h3>
          <div className="form-name-row">
            <TextField
              aria-label="First name"
              error={firstNameError}
              id="firstName"
              maxLength={64}
              minLength={1}
              placeholder="First name"
              title="First name"
              value={personInfo?.firstName}
              onChange={handleOnChange}
            />
            <TextField
              aria-label="Last name"
              error={lastNameError}
              id="lastName"
              maxLength={64}
              minLength={1}
              placeholder="Last name"
              title="Last name"
              value={personInfo?.lastName}
              onChange={handleOnChange}
            />
          </div>
          <TextField
            aria-label="Email"
            error={emailError}
            id="email"
            placeholder="Email"
            title="Email"
            value={personInfo?.email}
            onChange={handleOnChange}
          />
          <Select
            aria-label="Organizations"
            className="form-field-dropdown org-dropdown"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            defaultValue={defaultOrg}
            id="organization"
            menuPortalTarget={document.body}
            name="organizations"
            options={orgOptions}
            placeholder="Organizations"
            styles={{
              menuPortal: base => {
                return {
                  ...base,
                  zIndex: 9999,
                  color: 'rgba(130, 130, 130, 1)'
                };
              },
              multiValueLabel: base => {
                return {
                  ...base,
                  color: 'rgba(130, 130, 130, 1)',
                  fontSize: '14px',
                  margin: '.25rem'
                };
              }
            }}
            theme={(theme) => themeOptions(theme)}
            isMulti
            onChange={handleOrgSelect}
          />
          <h3>Permission group</h3>
          <p className="permission-description">Permissions are granted on a per-group basis, you can change these permissions in the "Permission Groups" tab.</p>
          <Select
            aria-label="Permission group"
            className="form-field-dropdown"
            classNamePrefix="select"
            id="permissionGroup"
            menuPortalTarget={document.body}
            name="permissionGroup"
            options={groupOptions}
            placeholder="Select permission group"
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: 'rgba(130, 130, 130, 1)' }) }}
            theme={(theme) => themeOptions(theme)}
            onChange={handleGroupSelect}
          />
          <PermissionsInfographic permissions={groupSelection} />
        </DialogContent>
        <DialogFooter className={cx(groupSelection?.length > 8 && 'permissions-overflow', 'action-container')}>
          <Button
            aria-label="create-user"
            disabled={pendingAction || disableSave}
            theme="primary"
            themeType="contained"
            onClick={handleCreateUser}
          >
            save changes
          </Button>
        </DialogFooter>
      </Dialog>
      <SendRegistrationModal externalKeys={[externalKey]} visible={registrationModalVisible} onRequestClose={handleRegistrationClick} />
    </div>
  );
};

export default CreateUserModal;
