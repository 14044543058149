import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FontIcon,
  useAddMessage
} from 'react-md';
import Select from 'react-select';
import { useGlobalContext } from '..';
import { useMutation } from '@apollo/client';
import { ADD_PERSONS_TO_PERMISSION_GROUP_MUTATION } from 'src/graphql/addPersonsToPermissionGroup.mutation';
import _ from 'lodash';
import './AddPersonsToGroupModal.scss';

export const AddPersonsToGroupModal = ({
  deselectAll,
  externalKeys,
  permissionGroups,
  visible,
  onRequestClose
}: { deselectAll: () => void, externalKeys: string[], permissionGroups: [any], visible: boolean, onRequestClose: () => void }) => {
  const { personsRefetch } = useGlobalContext();
  const [permissionGroupExternalKey, setPermissionGroupExternalKey] = useState<string>('');
  const addMessage = useAddMessage();

  const [addPersonsToPermissionGroupMutation] = useMutation(ADD_PERSONS_TO_PERMISSION_GROUP_MUTATION, {
    onCompleted: () => {
      addMessage({
        children: <><FontIcon style={{ color: '#4caf50' }}>check_circle_outline</FontIcon>&nbsp; Permission group{externalKeys.length > 1 && 's'} updated successfully.</>,
        action: 'dismiss'
      });

      personsRefetch();
      deselectAll();
      onRequestClose();
    },
    onError: (error) => {
      const message = error.message.search('not a member of this organization') ?
        'One or more of these users cannot be assigned to this group.' :
        'Error updating permission group';

      addMessage({
        children: <><FontIcon style={{ color: '#ff4f4f' }}>error_outline</FontIcon>&nbsp; {message}.</>,
        action: 'dismiss'
      });

      deselectAll();
      onRequestClose();
    }
  });

  const handleClick = () => {
    addPersonsToPermissionGroupMutation({
      variables: {
        permissionGroupExternalKey,
        personExternalKeys: externalKeys
      }
    });

    onRequestClose();
  };

  const onExit = () => {
    setPermissionGroupExternalKey('');
    onRequestClose();
  };

  const handleSelection = (selection: any) => setPermissionGroupExternalKey(selection.value);

  const groupOptions = _.sortBy(permissionGroups, [(o) => { return o.displayName.toLowerCase(); }]).map((p: { displayName: string; externalKey: string; }) => {
    return { label: p.displayName, value: p.externalKey };
  });

  return (
    <Dialog
      aria-labelledby="permission-group-dialog"
      id="permission-group-dialog"
      visible={visible}
      onExited={onExit}
      onRequestClose={onRequestClose}
    >
      <DialogContent className="dialog-content">
        <p className="dialog-title">
          {`Change ${externalKeys.length > 1 ? `${externalKeys.length} accounts` : 'account'} to the following permission group:`}
        </p>
        <Select
          className="form-field-dropdown"
          classNamePrefix="select"
          id="permission-group"
          menuPlacement="auto"
          menuPortalTarget={document.body}
          name="permission-group"
          options={groupOptions}
          placeholder="Select permission group"
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color: 'rgba(130, 130, 130, 1)' }) }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'rgba(80, 163, 255, .1)',
              primary: 'rgba(80, 163, 255, 1)'
            }
          })}
          onChange={(value) => handleSelection(value)}
        />
        <div className="dialog-actions">
          <Button
            aria-label="cancel-add-user"
            className="cancel-button"
            themeType="contained"
            onClick={onRequestClose}
          >
            cancel
          </Button>
          <Button
            aria-label="add-user"
            theme="primary"
            themeType="contained"
            onClick={handleClick}
          >
            save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddPersonsToGroupModal;
