import { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { LinearProgress, Button } from 'react-md';
import { PermissionGroupQuery_permissionGroups } from '../../../types/PermissionGroupQuery';
import { SearchInput, ListLoading, Table, TableHeadItem, TableBodyItem, useGlobalContext } from '..';
import { Arrow } from '../../assets/react-icons';
import { AVAILABLE_PERMISSIONS_QUERY, ORGS_QUERY } from '../../graphql';
import { isNotEmpty, FUSE_OPTIONS, getAuthUser } from '../../utils';
import Fuse from 'fuse.js';
import PermissionGroupModal from '../PermissionGroupModal/PermissionGroupModal';
import './PermissionGroups.scss';

interface IBodyElementChild {
  displayValue: string | number;
  sortValue: string | number;
}

export interface IBodyElement {
  name: IBodyElementChild;
  users_in_group: IBodyElementChild;
  edit_permissions: IBodyElementChild;
  externalKey: string;
}

interface IPermission {
  scope: string;
  path: string;
}

interface IEditPermissions {
  displayName: string;
  externalKey: string;
  permissions: [IPermission];
  organizationKey: string;
}

function PermissionGroups() {
  const ref = useRef<HTMLInputElement>(null);
  const authUser = getAuthUser();
  const { permissionGroupData, permissionGroupError, permissionGroupLoading } = useGlobalContext();
  const [groups, setGroups] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [createGroupModal, setCreateGroupModal] = useState<boolean>(false);
  const { data } = useQuery(AVAILABLE_PERMISSIONS_QUERY, { variables: { expand: true } });
  const { data: orgs, loading: orgsLoading } = useQuery(ORGS_QUERY, { variables: { externalKey: authUser.pk } });
  const [editPermission, setEditPermission] = useState<IEditPermissions>();

  useEffect(() => {
    if (permissionGroupData?.permissionGroups) {
      setGroups(permissionGroupData?.permissionGroups);
    }
  }, [permissionGroupData?.permissionGroups, setGroups]);

  const _FUSE_OPTIONS = {
    ...FUSE_OPTIONS,
    keys: [
      'displayName'
    ]
  };

  function createFilteredList() {
    const searchList = groups;
    const fuse = new Fuse(searchList, _FUSE_OPTIONS);
    const results = fuse.search(searchTerm);
    const formattedResults = results.map(result => result.item);

    return searchTerm === '' ? groups : formattedResults;
  }

  const groupList = createFilteredList();

  const body: TableBodyItem[] = groupList?.map((group: PermissionGroupQuery_permissionGroups) => {
    const { externalKey = '', displayName = '', persons = '', permissionsSummary } = group;
    const permissions = `${permissionsSummary?.write || 0} access`;

    return {
      name: {
        displayValue: displayName || '-',
        sortValue: displayName?.toLowerCase() || ''
      },
      users_in_group: {
        displayValue: persons?.length || '0',
        sortValue: persons?.length || 0
      },
      edit_permissions: {
        displayValue: permissions || '-',
        sortValue: permissionsSummary?.write || 0
      },
      action: {
        displayValue: (
          <Button
            buttonType="icon"
            className="action"
            onClick={() => handleEditGroupModal(externalKey)}
          >
            <Arrow />
          </Button>
        ),
        sortValue: ''
      }
    };
  }).sort((a: IBodyElement, b: IBodyElement) => a.name.sortValue > b.name.sortValue ? 1 : -1);

  const head: TableHeadItem[] = [{
    id: 'name',
    label: 'Name',
    sortable: true
  }, {
    id: 'users_in_group',
    label: 'Users in Group',
    sortable: true
  }, {
    id: 'edit_permissions',
    label: 'Permissions',
    sortable: true
  }, {
    label: '',
    id: 'action',
    sortable: false,
    style: { width: '3.5rem' }
  }];

  const removeSearch = () => {
    if (ref.current) {
      ref.current.value = '';
    }

    setSearchTerm('');
  };

  function handleCreateGroupModal() {
    setEditPermission(undefined);
    removeSearch();

    return setCreateGroupModal(!createGroupModal);
  }

  function handleEditGroupModal(externalKey: string | null) {
    const selection = permissionGroupData?.permissionGroups?.find((group: PermissionGroupQuery_permissionGroups) => group.externalKey === externalKey);

    const editPermissions = {
      permissions: selection?.permissions,
      displayName: selection?.displayName,
      externalKey: selection?.externalKey,
      organizationKey: selection?.organizationKey
    };

    setEditPermission(editPermissions);
    removeSearch();

    return setCreateGroupModal(!createGroupModal);
  }

  function handleSearch(value: string) {
    setSearchTerm(value);
  }

  return (
    <>
      <div className="progress-container">
        {permissionGroupLoading && <LinearProgress id="persons-linear-progress" />}
      </div>
      <section className="wrapper permissions-container">
        <div className="top-action-bar">
          <SearchInput
            ref={ref}
            placeholder="Search by Keyword"
            onChange={(value) => handleSearch(value)}
          />
          <Button
            aria-label="create-user"
            className="create-user"
            disabled={permissionGroupLoading || orgsLoading}
            theme="primary"
            themeType="contained"
            onClick={handleCreateGroupModal}
          >
            create new group
          </Button>
        </div>
        {permissionGroupError && <div>Error...</div>}
        {permissionGroupLoading && !isNotEmpty(groups) && <ListLoading />}
        {isNotEmpty(groups) && (
          <Table
            body={body}
            defaultSortColumn='name'
            defaultSortDirection='asc'
            head={head}
          />
        )}
      </section>
      <PermissionGroupModal
        availablePermissions={data?.availablePermissions}
        editPermission={editPermission}
        orgs={orgs?.persons?.persons[0].organizations}
        visible={createGroupModal}
        onRequestClose={handleCreateGroupModal}
      />
    </>
  );
};

export default PermissionGroups;
