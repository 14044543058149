import { gql } from '@apollo/client';

export const INVITE_PERSONS_MUTATION = gql`
  mutation InvitePersonsMutation(
    $externalKeys: [ID]
  ) {
    invitePersons(
      externalKeys: $externalKeys
    ) {
      personsInvited
      recipients
    }
  }
`;
