//@ts-nocheck
import { Button } from '@parkhub/parkhub-ui';
import { useFilterGroupContext } from './FilterGroupContext';
import FilterGroupListItem from './FilterGroupListItem';
import { isNotEmpty } from '../../utils';

const FilterMenu = ({
  headerLabel
}) => {
  const {
    activeFilterCount,
    activeFilters,
    clearAllFilters,
    setIsLayoutPopperOpen,
    filterConfig,
    navigateNext
  } = useFilterGroupContext();

  const handleClearAll = () => {
    clearAllFilters();
    setIsLayoutPopperOpen(false);
  };

  return (
    <>
      <div className="filter-group-menu-header">
        <span className="chip-label">{headerLabel}</span>
        <Button
          aria-label="close"
          className="filter-group-header-button white small"
          icon="close"
          onClick={() => setIsLayoutPopperOpen(false)}
        />
      </div>
      <div className="filter-group-content">
        <ul className="filter-group-list">
          {
            filterConfig.map((filter) => (
              <FilterGroupListItem
                key={filter.variableKey}
                action={() => navigateNext(filter.variableKey)}
                active={isNotEmpty(activeFilters?.[filter.variableKey])}
                disabled={!isNotEmpty(filter.filterOptions)}
                label={filter.label}
              />
            ))
          }
        </ul>
      </div>
      <div className="filter-group-action-container">
        {!!activeFilterCount &&
          <Button
            className="filter-group-popover-primary-action filled gray full"
            onClick={handleClearAll}
          >
            CLEAR ALL FILTERS
          </Button>
        }
      </div>
    </>
  );
};

export default FilterMenu;
