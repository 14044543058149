import FilterGroupLayout from './FilterGroupLayout';
import { FilterGroupContextProvider, useFilterGroupContext } from './FilterGroupContext';
import './FilterGroup.scss';

interface IProps {
  label: string;
  filterConfig: object;
  className?: string;
  style?: object;
  onUpdate: (filterConfig: object) => void;
  onClear?: () => void;
  popperOptions?: object;
}

const FilterGroup = ({
  label,
  filterConfig,
  className,
  style,
  onUpdate,
  onClear,
  popperOptions
}: IProps) => (
  <FilterGroupContextProvider
    filterConfig={filterConfig}
    popperOptions={popperOptions}
    onClear={onClear}
    onUpdate={onUpdate}
  >
    <FilterGroupLayout
      className={className}
      label={label}
      style={style}
    />
  </FilterGroupContextProvider>
);

FilterGroup.defaultProps = {
  className: null,
  label: 'Filters',
  popperOptions: {
    placement: 'bottom-start',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 6]
      }
    }]
  },
  style: null
};

export {
  FilterGroup,
  useFilterGroupContext
};
