import { gql } from '@apollo/client';

export const PERSONS_QUERY = gql`
  query PersonsQuery(
    $externalKey: [ID]
    $limit: Int
    $offset: Int
    $permissionGroupExternalKey: [ID]
    $status: [String]
    $includeDeactivated: Boolean
    $search: String
  ) {
    persons(
      externalKey: $externalKey
      limit: $limit
      offset: $offset
      permissionGroupExternalKey: $permissionGroupExternalKey
      status: $status
      includeDeactivated: $includeDeactivated
      search: $search
    ) {
      persons {
        displayName
        email
        externalKey
        firstName
        lastName
        organizations {
          externalKey
          displayName
          permissionGroups {
            externalKey
            displayName
            permissions {
              scope
              path
            }
          }
        }
        permissionGroups {
          externalKey
          displayName
          organizationKey
          permissions {
            scope
            path
          }
        }
        status
      }
    }
  }
`;
